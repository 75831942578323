import * as React from 'react'
import { graphql } from 'gatsby'
import { LayoutTailwindDefault } from '@/layouts/layout-tailwind-default'
import { ProductListing } from '@/components/tailwind/product-listing'
import { Seo } from '@/components/tailwind/seo'
import { ProductFilteredList } from '@/components/tailwind/product-filtered-list'

import { ShopifyCollection, PageInfo, SitePage } from '@/gatsby-types'

type ShopifyCollectionArgs = {
  data: {
    collection: ShopifyCollection
    pageInfo: PageInfo
  }
  pageContext: SitePage['pageContext']
}

const ProductTypeIndex = ({
  data: { collection, pageInfo },
  pageContext: { handle },
}: ShopifyCollectionArgs) => {
  return (
    <LayoutTailwindDefault>
      <Seo title={`Collection: ${collection.title}`} />
      <ProductFilteredList
        title={collection.title}
        pageContext={collection.title}
        data={{
          products: {
            nodes: collection.products,
            pageInfo,
          },
        }}
      />
    </LayoutTailwindDefault>
  )
}

export const query = graphql`
  query ShopifyCollectionQuery($handle: String!) {
    collection: shopifyCollection(handle: { eq: $handle }) {
      id
      title
      products {
        ...ProductCard
      }
    }
  }
`

export default ProductTypeIndex
